import config from '../../config.json';
import { formatQueryString, removeTokenFromLocalStorage, saveTokenToLocalStorage, tokenAuthorization } from '../../js/requestHelpers';

const CONTROLLER_PROTOCOL = process.env.REACT_APP_CONTROLLER_PROTOCOL || window._env_?.controllerProtocol || config.controllerProtocol || "http" 
const CONTROLLER_HOST = process.env.REACT_APP_CONTROLLER_HOST || window._env_?.controllerHost || config.controllerHost || window.location.hostname
const CONTROLLER_PORT = process.env.REACT_APP_CONTROLLER_PORT || window._env_?.controllerPort || config.controllerPort

// const BASE_AUTHORIZATION_URL = `http://${CONTROLLER_HOST}:${CONTROLLER_PORT}`
const BASE_AUTHORIZATION_URL = `${CONTROLLER_PROTOCOL}://${CONTROLLER_HOST}:${CONTROLLER_PORT}/api/v4` // hardcode to reach the service controller on 168

export const methods = {

}



/* 
:::::::::::::::::::::::::::::::::::::::::::
::::::::::::::::::: API :::::::::::::::::::
:::::::::::::::::::::::::::::::::::::::::::
*/

export const API = {

    async login({ username, password }) {
        const headers = new Headers();
        headers.append("Content-Type", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();

        const requestOptions = {
            method: "POST",
            headers,
            body: `username=${username}&password=${password}`,
            redirect: "follow",
        }

        return new Promise(resolve => {
            fetch(BASE_AUTHORIZATION_URL + "/login", requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data.access_token) {
                        window._tokenAccessor = Symbol("TOKEN_ACCESSOR");
                        window[window._tokenAccessor] = data.access_token;
                        saveTokenToLocalStorage(data.access_token);
                        resolve({
                            user_id: data.id,
                            user_name: data.username,
                            token: data.access_token,
                        })
                    } else {
                        resolve({ error: "Login was not successful. The Backend did not return an access token for this user" })
                    }

                })
                .catch(err => resolve({ error: "No user found with these credentials" }))
        })

    },

    renew() {
        
        if(!window[window._tokenAccessor] && !localStorage.CASI_TOKEN) return new Promise(r => r(false)); // we need the token to call this

        const headers = tokenAuthorization();
        headers.append("Content-Type", "application/x-www-form-urlencoded");
        const requestOptions = {
            method: "POST",
            headers,
            redirect: "follow",
        }
        return new Promise(resolve => {
            fetch(BASE_AUTHORIZATION_URL + "/renew", requestOptions)
                .then(response => response.json())
                .then(data => {
                    if(data.access_token) {
                        if(!window._tokenAccessor) {
                            window._tokenAccessor = Symbol("TOKEN_ACCESSOR");
                        }
                        window[window._tokenAccessor] = data.access_token;
                        saveTokenToLocalStorage(data.access_token);
                        resolve({
                            user_id: data.id,
                            user_name: data.username,
                            token: data.access_token,
                            expires: data.expires
                        })
                    } else {
                        resolve(false)
                    }
                })
                .catch(err => {
                    console.error(err)
                    resolve(false);
                })
        })
    },

    logout() {
        const headers = tokenAuthorization(this.state.token);
        const requestOptions = {
            method: "POST",
            headers,
            body: "",
            redirect: "follow"
        }
        
        fetch(`${BASE_AUTHORIZATION_URL}/logout`, requestOptions)
            .then(response => {
                console.log(response)
                if (response.status) {
                    this.setters.clearUser()
                    delete window[window._tokenAccessor ?? ""];
                    delete window._tokenAccessor;
                    removeTokenFromLocalStorage();
                }
            })

    },

    getGeoserverToken(){
        const headers = tokenAuthorization();
        return new Promise(resolve => {
            fetch(BASE_AUTHORIZATION_URL + "/geoserver-token", {method: "POST", body: "{}", headers })
                .then(response => response.json())
                .then(data => {
                    if(!window._geoserverTokenAccessor) {
                        window._geoserverTokenAccessor = Symbol("GEOSERVER_TOKEN");
                    }
                    window[window._geoserverTokenAccessor] = data
                    resolve(data)
                })
                .catch(err => {
                    console.error(err)
                    resolve(null)
                })
        })
    }


}


