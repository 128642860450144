import React, { useContext, useEffect, useState } from 'react'
import { DateTime } from 'luxon'

// ========================== STATE ==========================
import { DataPrepContext } from '../../../state/explorer/explorerProvider'

// =============================== CONFIG ===============================
import config from "../../../config.json"
import InlineButton from '../../dzyne_components/ui/buttons/InlineButton/InlineButton';

const GEOSERVER_PROTOCOL = process.env.REACT_APP_GEOSERVER_PROTOCOL || window._env_?.geoserverProtocol || config.geoserverProtocol || "http";
const GEOSERVER_HOST = process.env.REACT_APP_GEOSERVER_HOST || window._env_?.geoserverHost || config.geoserverHost || window.location.hostname;
const GEOSERVER_PORT = process.env.REACT_APP_GEOSERVER_PORT || window._env_?.geoserverPort || config.geoserverPort || 4013

export default function ResultsTimeline({ detectionResults }) {

    // STATE
    const { state, setters, dataPrepAPI } = useContext(DataPrepContext)
    const [addedLayers, setAddedLayers] = useState([])
    const [resultIndex, setResultIndex] = useState(-1)
    const [resultDate, setResultDate] = useState(null)

    // EVENTS
    const handleChangeResult = direction => () => {
        if (direction === "increment") {
            setResultIndex(Math.min(resultIndex + 1, addedLayers.length - 1))
        } else if (direction === "decrement") {
            setResultIndex(Math.max(0, resultIndex - 1))
        }
    }


    // EFFECTS
    useEffect(() => {
        const appendLayers = async () => {
            let resultLayer,
                layer,
                imageData,
                layers = []
            for (let [i, data] of Object.entries(detectionResults)) {
                resultLayer = {
                    layerType: "feature",
                    type: "dzyne_server",
                    name: `detection_results_${data.catID}`,
                    layerLocation: "casi:detection_results",
                    ip: GEOSERVER_HOST,
                    port: GEOSERVER_PORT,
                    mapSettings: {
                        viewparams: `image_catid:${data.catID};run_id:${data.runID}`
                    },
                    aoi: { type: "none" },
                    isActive: i == 0,
                }

                layer = await setters.appendLayer(resultLayer)
                imageData = await dataPrepAPI.getImage(data.catID)
                if (layer.success) layers.push({ layer: layer.layer, timestamp: DateTime.fromISO(imageData.timestamp).ts })
            }
            layers = layers.sort((a, b) => a.timestamp - b.timestamp) // sort layers by time
            setAddedLayers(layers)
        }
        appendLayers()
    }, [])


    // handle the result change
    useEffect(() => {
        let date
        for (let [i, data] of Object.entries(addedLayers)) {
            const { layer, timestamp } = data
            if (i == resultIndex) {
                layer && setters.toggleLayer(layer.id, "active")
                date = DateTime.fromMillis(timestamp)
                setResultDate(date.toFormat("DD"))
            } else {
                layer && setters.toggleLayer(layer.id, "inactive")
            }
        }

    }, [resultIndex, addedLayers])

    useEffect(() => {

        return () => {
            console.log("UNMOUNTING", addedLayers)
            for (let layer of addedLayers) {
                setters.deleteLayer(layer.layer.id)
            }
        }
    }, [addedLayers])

    return (
        <>
            <InlineButton onClick={handleChangeResult("decrement")}>{"<"}</InlineButton>
            <span>{resultDate}</span>
            <InlineButton onClick={handleChangeResult("increment")}>{">"}</InlineButton>
        </>
    )
}
