import config from "../../config.json";
import { DateTime } from "luxon";
import _ from "lodash";
import {
    applyGeometryQuery,
    captureCurrentView,
    rectangleToWKT,
} from "../../js/mapHelpers";
import {
    authorize,
    feature2RawSrc,
    feature2ProcessedThumbnail,
    formatQueryString,
    tokenAuthorization,
} from "../../js/requestHelpers";
import {
    dateRangeToCQL,
    removeDateRangeFromCQL,
} from "../../js/sessionHelpers";
const Cesium = require("cesium");
const axios = require("axios").default;

// ======================= CONFIG =======================
const CONTROLLER_PROTOCOL =
    process.env?.REACT_APP_CONTROLLER_PROTOCOL ||
    window._env_?.controllerProtocol ||
    config.controllerProtocol ||
    "http";
const CONTROLLER_HOST =
    process.env?.REACT_APP_CONTROLLER_HOST ||
    window._env_?.controllerHost ||
    config.controllerHost ||
    window.location.hostname;
const CONTROLLER_PORT =
    process.env?.REACT_APP_CONTROLLER_PORT ||
    window._env_?.controllerPort ||
    config.controllerPort ||
    4011;

const CONTROLLER_BASE = `${CONTROLLER_PROTOCOL}://${CONTROLLER_HOST}:${CONTROLLER_PORT}/api/v4/`;

const controller = axios.create({ baseURL: CONTROLLER_BASE })
controller.interceptors.request.use(config => {
    const token = window[window._tokenAccessor];
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
})

const GEOSERVER_PROTOCOL =
    process.env.REACT_APP_GEOSERVER_PROTOCOL ||
    window._env_?.geoserverProtocol ||
    config.geoserverProtocol ||
    "http";
const GEOSERVER_HOST =
    process.env.REACT_APP_GEOSERVER_HOST ||
    window._env_?.geoserverHost ||
    config.geoserverHost ||
    window.location.hostname;
const GEOSERVER_PORT =
    process.env.REACT_APP_GEOSERVER_PORT ||
    window._env_?.geoserverPort ||
    config.geoserverPort ||
    4013;
const GEOSERVER_BASE = `${GEOSERVER_PROTOCOL}://${GEOSERVER_HOST}:${GEOSERVER_PORT}/geoserver`;

const GEOSERVER_USERNAME =
    process.env?.REACT_APP_GEOSERVER_USERNAME ||
    window._env_?.geoserverUsername ||
    config.geoserverUsername;
const GEOSERVER_PASSWORD =
    process.env?.REACT_APP_GEOSERVER_PASSWORD ||
    window._env_?.geoserverPassword ||
    config.geoserverPassword;

const LINK_SHORTENER_HOST =
    process.env?.REACT_APP_LINK_SHORTENER_HOST ||
    window._env_?.linkShortenerHost ||
    config.linkShortenerHost;
const LINK_SHORTENER_PORT =
    process.env?.REACT_APP_LINK_SHORTENER_PORT ||
    window._env_?.linkShortenerPort ||
    config.linkShortenerPort;
const LINK_SHORTENER_API_KEY =
    process.env?.REACT_APP_LINK_SHORTENER_API_KEY ||
    window._env_?.linkShortenerApiKey ||
    config.linkShortenerApiKey;

const methods = {
    moveSelectedLayer(level) {
        this.state.selectedLayer &&
            this.setters.adjustRenderedLayerLevel(
                this.state.selectedLayer.id,
                level
            );
    },

    // HANDLING BASE LAYERS
    addBaseLayer(layer) {
        const baseLayers =
            this.state.viewer.baseLayerPicker.viewModel
                .imageryProviderViewModels;

        const layerAlreadyAdded = !!baseLayers.find(
            (bl) => bl.tooltip === layer.id
        ); // tooltip being used as the layer ID

        if (!layerAlreadyAdded) {
            // Only add the layer to the base layer picker if it hasn't already been added
            const newBaseLayer = new Cesium.ProviderViewModel({
                name: layer.name,
                iconUrl: "",
                category: "User Base Layers",
                tooltip: layer.id, // use the tooltip to store the id
                id: layer.id,
                creationFunction: function () {
                    return new Cesium.UrlTemplateImageryProvider({
                        url: layer.url,
                    });
                },
            });

            baseLayers.unshift(newBaseLayer);
        }
    },

    setActiveBaseLayer(layerID) {
        // const baseLayers = this.state.viewer.baseLayerPicker.viewModel.imageryProviderViewModels
        const imageryLayers = this.state.viewer.imageryLayers;

        const currentBaseLayer = imageryLayers.get(0);

        let selectedBaseLayer;
        for (let layer of this.state.layers) {
            if (layer.layerType === "base") {
                if (layer.id === layerID) {
                    selectedBaseLayer = layer;
                    if (selectedBaseLayer.isActive) return; // if the selected layer was already the rendered base layer, don't do anything
                    this.setters.toggleLayer(layer.id, "active");
                } else {
                    this.setters.toggleLayer(layer.id, "inactive");
                    this.setters.unregisterRenderedLayer(layer.id);
                }
            } else {
                continue;
            }
        }

        // remove currentBaseLayer
        imageryLayers.remove(currentBaseLayer, false);
        // add selectedBaseLayer in it's place

        let newBaseLayer;
        switch (selectedBaseLayer.type) {
            case "dzyne_server":
                newBaseLayer = imageryLayers.addImageryProvider(
                    new Cesium.WebMapServiceImageryProvider({
                        url: `${GEOSERVER_PROTOCOL}://${selectedBaseLayer.ip}:${selectedBaseLayer.port}/geoserver/wms`,
                        layers: selectedBaseLayer.layerLocation,
                        // getFeatureInfoParameters,

                        // getFeatureInfoFormats: [new Cesium.GetFeatureInfoFormat("json", "application/json", data => {
                        //     this.setters.setLayerInfoBox(data)
                        // })],

                        defaultAlpha: 0.1,
                        parameters: {
                            transparent: true,
                            format: "image/png",
                            ...selectedBaseLayer.mapSettings,
                        },
                    }),
                    0
                );
                break;
            case "url":
                newBaseLayer = imageryLayers.addImageryProvider(
                    new Cesium.UrlTemplateImageryProvider({
                        url: selectedBaseLayer.url,
                    }),
                    0
                );
                break;
            // case "terrain_url":
            //     newBaseLayer = new Cesium.CesiumTerrainProvider({
            //         url: selectedBaseLayer.url
            //     })
            //     break;
        }

        this.setters.registerRenderedLayer(layerID, newBaseLayer);

        this.setters.setBaseLayer(newBaseLayer);
    },

    setActiveTerrainLayer(layerID) {
        this.setters.toggleTerrainLayer(layerID);
    },

    // HANDLING LAYER DRAWING

    drawLayerOnMap(layerID) {
        const layer = this.state.layers.find((l) => l.id === layerID);
        let newLayer;
        switch (layer.type) {
            case "dzyne_server":
                const getFeatureInfoParameters = { feature_count: 100 };
                if (layer.mapSettings?.CQL_FILTER)
                    getFeatureInfoParameters.cql_filter =
                        layer.mapSettings.CQL_FILTER;

                newLayer = new Cesium.WebMapServiceImageryProvider({
                    url: `${GEOSERVER_PROTOCOL}://${layer.ip}:${layer.port}/geoserver/wms`,
                    layers: layer.layerLocation,
                    getFeatureInfoParameters,

                    getFeatureInfoFormats: [
                        new Cesium.GetFeatureInfoFormat(
                            "json",
                            "application/json",
                            async (data) => {
                                this.setters.updateLayerInfoBox(layerID, data);
                            }
                        ),
                    ],

                    defaultAlpha: 0.5,
                    parameters: {
                        transparent: true,
                        format: "image/png",
                        ...layer.mapSettings,
                    },
                });

                this.setters.appendRenderedLayer(layerID, newLayer);
                break;

            case "url":
                newLayer = new Cesium.UrlTemplateImageryProvider({
                    url: layer.url,
                });
                this.setters.appendRenderedLayer(layerID, newLayer);
                break;
            case "terrain_url":
                /*
                 *** LOGIC MOVED TO FORM CONTAINER COMPONENT CA LN 150
                 */

                // switch(layer.terrainType){
                //     case "cesium":
                //         newLayer = new Cesium.CesiumTerrainProvider({
                //             url: layer.url
                //         })
                //         break;
                //     case "google":
                //         break;
                //     case "vr":
                //         break;
                // }
                // this.setters.appendRenderedTerrain(layerID, newLayer);
                break;
        }

        return newLayer;
    },

    forceRenderSharedTerrain(layers, viewer) {
        // the purpose of this function is to apply a shared terrain layer whose isActive flag is set to true PRIOR to the viewer being set in state.
        for (let layer of layers) {
            if (layer.layerType === "terrain" && layer.isActive) {
                this.setters.toggleTerrainLayer(layer.id, {
                    force: true,
                    viewer,
                });
            }
        }
    },

    renderPolygonFromFeatureInfo(feature, options = { fitBounds: false }) {
        try {
            const coordinates = [].concat.apply(
                [],
                feature.geometry.coordinates[0]
            );

            const polygon = this.state.viewer.entities.add({
                polygon: {
                    hierarchy: Cesium.Cartesian3.fromDegreesArray(coordinates),
                    height: 0,
                    outline: true,
                    outlineColor: Cesium.Color.RED,
                    outlineWidth: 4,
                    material: Cesium.Color.BLACK.withAlpha(0),
                },
            });

            options.fitBounds && this.state.viewer.zoomTo(polygon);
            return polygon;
        } catch (err) {
            return null;
        }
    },

    removeEntity(entity) {
        this.state.viewer.entities.remove(entity);
    },

    fitToEntity(entity) {
        // !!entity && this.state.viewer.zoomTo(entity)
        !!entity &&
            this.state.viewer.flyTo(entity, {
                duration: 0.5,
                offset: new Cesium.HeadingPitchRange(0, -90, 0),
            });
    },

    removeRegionAnnotations() {
        window._annotationRegistries?.regions?.deleteAll();
    },

    zoomToDefaultLocation({ viewer, zoom }) {
        viewer = viewer ?? this.state.viewer;
        zoom = zoom ?? this.state.globalSettings.defaultLocation;
        if (viewer) {
            switch (zoom.type) {
                case "point":
                    viewer.camera.flyTo({
                        destination: Cesium.Cartesian3.fromDegrees(
                            zoom.lng,
                            zoom.lat,
                            zoom.alt
                        ),
                        duration: 0.5,
                    });
                    break;
                case "corners":
                    // console.log("ZOOM: ", zoom, JSON.stringify(zoom))
                    // zoom = this.state.globalSettings.defaultLocation
                    // console.log("GLOBAL ZOOM: ", zoom)
                    const { heading, pitch, roll } = zoom;

                    let position;
                    switch (true) {
                        case !!zoom.positionCoords:
                            position = Cesium.Cartesian3.fromDegrees(
                                zoom.positionCoords.lng,
                                zoom.positionCoords.lat,
                                zoom.positionCoords.alt
                            );
                            if (
                                !!zoom.positionCoords.lng ||
                                !!zoom.positionCoords.lat
                            )
                                break;
                        case !!zoom.position:
                            position = zoom.position; // already a cartesian3 type
                            break;
                        default:
                            position = {
                                north: zoom.north,
                                east: zoom.east,
                                south: zoom.south,
                                west: zoom.west,
                            };
                    }

                    viewer.camera.flyTo({
                        destination: position,
                        orientation: { heading, pitch, roll },
                        duration: 0.5,
                    });

                    break;
                case "draw":
                    break;
            }
        }
    },

    pollViewGeometry(layerID) {
        const layer = this.state.layers.find((l) => l.id === layerID);

        // if we're not dynamically polling the view, clear an interval if it previously existed, delete record of it, and don't setup a new one
        if (layer.aoi?.type !== "dynamic") {
            clearInterval(window[`_poll_view_geometry_${layerID}`]);
            delete window[`_poll_view_geometry_${layerID}`];
            return;
        }

        let selectedLayer, rect, wkt, updatedCQLString;
        // if no polling interval exists, establish one
        if (!window[`_poll_view_geometry_${layerID}`]) {
            // interval
            window[`_poll_view_geometry_${layerID}`] = setInterval(async () => {
                selectedLayer = this.getters.getSelectedLayer();
                if (
                    selectedLayer?.id === layerID &&
                    selectedLayer?.aoi?.type === "dynamic"
                ) {
                    // get the rectangle of the viewer camera and convert to WKT (POLYGON)
                    rect = captureCurrentView(this.getters.getViewer());
                    wkt = rectangleToWKT(rect, true); // true = reverse lat lng positions

                    // update the CQL filter with a new INTERSECTS query from the map's current view bounds
                    updatedCQLString = applyGeometryQuery(
                        selectedLayer?.mapSettings?.CQL_FILTER,
                        wkt
                    );

                    // if there has been no change in the viewer bounds, then don't update and redraw the layer
                    if (
                        updatedCQLString ===
                        selectedLayer?.mapSettings?.CQL_FILTER
                    )
                        return;
                    await this.setters.applyMapSettingToLayer(
                        selectedLayer.id,
                        "CQL_FILTER",
                        updatedCQLString
                    );

                    // redraw the layer after the update has taken place
                    this.setters.redrawRenderedLayer(selectedLayer.id);
                } else {
                    clearInterval(window[`_poll_view_geometry_${layerID}`]);
                    delete window[`_poll_view_geometry_${layerID}`];

                    if (selectedLayer?.id === layerID) {
                        // this triggers when the aoi type is moved off of dynamic
                        updatedCQLString = applyGeometryQuery(
                            selectedLayer?.mapSettings?.CQL_FILTER,
                            null
                        );
                        // update the CQL_FILTER parameter, removing it if the updatedCQLString is an empty string.
                        await this.setters.applyMapSettingToLayer(
                            selectedLayer.id,
                            "CQL_FILTER",
                            updatedCQLString,
                            { remove: !updatedCQLString }
                        );

                        // redraw the layer after the update has taken place
                        this.setters.redrawRenderedLayer(selectedLayer.id);
                    }
                }
            }, 2000);
        }
    },

    async applyUserGeometry(layerID, wktGeometry) {
        const layer = this.state.layers.find((l) => l.id === layerID);
        if (layer) {
            const updatedCQLString = applyGeometryQuery(
                layer?.mapSettings?.CQL_FILTER,
                wktGeometry
            );

            if (updatedCQLString === layer?.mapSettings?.CQL_FILTER) return;

            await this.setters.applyMapSettingToLayer(
                layerID,
                "CQL_FILTER",
                updatedCQLString,
                { remove: !updatedCQLString }
            );
            this.setters.redrawRenderedLayer(layerID);
        }
    },

    async applyDataPrepDateRange() {
        const { start_time: start, end_time: end } = this.state.selectedSession;
        let updatedCQL;
        for (let layer of this.state.selectedSession.session_data.layers) {
            if (layer.layerType !== "feature") continue;
            if ("_applyDateRange" in layer && layer._applyDateRange) {
                updatedCQL = dateRangeToCQL(start, end, layer);
            } else {
                updatedCQL = removeDateRangeFromCQL(layer);
            }
            if (!updatedCQL) continue;
            await this.setters.applyMapSettingToLayer(
                layer.id,
                "CQL_FILTER",
                updatedCQL,
                { isSelectedLayer: false }
            );
        }
    },

    renderDepthResults(depthJSON, options = { autoZoom: false }) {
        let buildingHeight;
        // 1. unify the building height across all coordinate points
        for (let feature of depthJSON.features) {
            if (!feature?.geometry?.coordinates?.length > 1) continue;
            buildingHeight =
                feature?.properties?.height ||
                feature?.properties?.avg_height ||
                feature.geometry?.coordinates?.[0][0][2]; // alternate: if no height in properties, use the first height/z value

            for (let coord of feature?.geometry?.coordinates) {
                coord.forEach((c) => (c[2] = buildingHeight));
            }
            // feature?.geometry?.coordinates?.[0]?.forEach(c => { c[2] = buildingHeight })
        }

        // 2. remove any old depth data source
        this.methods.clearDepthResults();

        // 3. add the data source to the map
        Cesium.GeoJsonDataSource.load(depthJSON).then((dataSource) => {
            this.state.viewer?.dataSources.add(dataSource);
            const entities = dataSource.entities.values;
            for (let entity of entities) {
                // entity._id = `DEPTH_ENTITY-${entity_id}`
                entity.isDepthEntity = true;
                entity.polygon.extrudedHeight = 0;
                entity.polygon.flat = true;
                entity.polygon.outline = false;
                entity.polygon.material = Cesium.Color.fromRandom({
                    maximumRed: 0.5,
                    maximumGreen: 0.7,
                    minimumBlue: 0.5,
                    alpha: 1.0,
                });
            }
            this.setters.setDepthDataSource(dataSource);
            if (options.autoZoom)
                this.state.viewer.flyTo(dataSource, { duration: 0.5 });
        });
    },

    clearDepthResults() {
        if (!!this.state.depthDataSource) {
            this.state.viewer.dataSources.remove(
                this.state.depthDataSource,
                true
            ); // second arg indicates to destroy the data source
        }
        this.setters.setDepthDataSource(null);
    },

    zoomToDepthDetection() {
        if (!!this.state.depthDataSource && !!this.state.viewer) {
            this.state.viewer.flyTo(this.state.depthDataSource, {
                duration: 0.5,
            });
        }
    },

    changeDepthEntityOpacity(opacity) {
        const entities =
            this.state.depthDataSource?._entityCollection?._entities?._array;
        for (let entity of entities) {
            entity.polygon.material.color = Cesium.Color.fromAlpha(
                entity.polygon.material.color._value,
                opacity
            );
        }
    },

    isValidImageSrc(url) {
        return new Promise((resolve) => {
            fetch(url)
                .then((response) => {
                    resolve(response.ok);
                })
                .catch((err) => {
                    resolve(false);
                });
        });
    },
};

/* 
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::::::::::::::::::::: DATA PREP API ::::::::::::::::::::::::::::::::
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
*/
export const dataPrepAPI = {

    createDataPrepSession(data) {
        return new Promise((resolve) => {
            controller.post("/data-prep-sessions", data).then(
                (response) => {
                    if (response.status === 201) {
                        this.setters.appendDataPrepSession(response.data, {
                            select: true,
                        });
                        resolve(response);
                    }
                },
                (err) => {
                    console.log(err);
                }
            );
        });
    },

    getDataPrepSessions(userID) {
        const params = new URLSearchParams(window.location.search);
        const selectedSessionName = params.get("session");
        const isolated = params.get("isolated") === "true";
        return new Promise((resolve) => {
            controller.get("/data-prep-sessions?type=data-prep")
                .then((response) => {
                    let { data } = response;
                    if (data) {
                        data = data
                            .map((session) => {
                                if (
                                    (session.name[0] === "_" &&
                                        session.name !== selectedSessionName) ||
                                    (isolated &&
                                        session.name !== selectedSessionName)
                                ) {
                                    return null;
                                }
                                session.changesDetected = false;
                                return session;
                            })
                            .filter((dp) => !!dp);
                    }

                    this.setters.setDpSessions(data);
                    resolve(response.data);
                });
        });
    },

    updateDataPrepSession(session) {
        if (typeof session.images?.[0] === "object") {
            session = { ...session };
            session.images = session.images.map(img => img.image_catid);
        }
        return new Promise((resolve) => {
            controller
                .put(`/data-prep-sessions/${session.id}`,
                    session
                )
                .then((response) => {
                    if (response.status === 200) {
                        const updatedSession = response.data;
                        updatedSession.changesDetected = false;
                        // updatedSession.images = session.images || [] // hard coded until we add this to the db model
                        this.setters.updateDataPrepSession(updatedSession);
                        resolve(response);
                    }
                });
        });
    },

    deleteDataPrepSession(sessionID) {
        return new Promise((resolve) => {
            controller
                .delete(`/data-prep-sessions/${sessionID}`)
                .then((response) => {
                    if (response.status === 204) {
                        this.setters.removeDataPrepSession(sessionID);
                    }
                    resolve(response);
                });
        });
    },

    getLabelSessions() {
        return new Promise(resolve => {
            controller
                .get("/data-prep-sessions?type=label")
                .then(response => {
                    resolve(response.data);
                })
                .catch(err => {
                    console.error(err)
                    resolve([])
                })
        })
    },

    getImage(catid) {
        return new Promise((resolve) => {
            controller
                .get(`/images/${catid}`)
                .then((response) => {
                    resolve(response.status === 200 ? response.data : null);
                })
                .catch((err) => resolve(null));
        });
    },

    getImageResult(catID, runID) {
        return new Promise(resolve => {
            controller.get(`/images/${catID}/child/${runID}`)
                .then(response => {
                    resolve(response.status === 200 ? response.data : null);
                })
                .catch(err => {
                    console.error(err)
                    resolve(null)
                })
        })
    },

    getAvailableDetectors() {
        return new Promise((resolve) => {
            controller
                .get("/engine-tasks/detect/available")
                .then((response) => {
                    resolve(response.status === 200 ? response.data : null);
                })
                .catch((err) => resolve(null));
        });
    },

    submitDetectionTask(data) {
        return new Promise((resolve) => {
            controller
                .post("/engine-tasks/detect", data)
                .then((response) => {
                    resolve(response.status === 201);
                })
                .catch((err) => {
                    console.log(err);
                    resolve(null);
                });
        });
    },

    submitChangeDetectionTask(data) {
        return new Promise((resolve) => {
            controller
                .post("/engine-tasks/changes", data)
                .then((response) => {
                    resolve(response.status === 201);
                })
                .catch((err) => {
                    console.log(err);
                    resolve(null);
                });
        });
    },

    submitTrainingJob(data) {
        return new Promise(resolve => {
            controller
                .post("/engine-tasks/train", data)
                .then(response => {
                    console.log(response);
                    resolve(response.status === 201);
                })
                .catch(err => {
                    console.error(err);
                    resolve(false);
                })
        })
    },

    createAnalysisArea(data) {
        return new Promise((resolve) => {
            controller
                .post("/areas", data)
                .then((response) => {
                    resolve(response.status === 201 ? response.data : null);
                })
                .catch((err) => {
                    console.log(err);
                    resolve(null);
                });
        });
    },

    executeAreaAnalysis(areaID) {
        return new Promise((resolve) => {
            controller
                .post(`/areas/${areaID}/analyze`)
                .then((response) => {
                    resolve(response.status === 201);
                })
                .catch((err) => {
                    console.log(err);
                    resolve(null);
                });
        });
    },

    getTasksBySessionID(sessionID) {
        // This is where the call will go that gets detections by session id
        return new Promise((resolve) => {
            controller
                .get(`/engine-tasks/detect/${sessionID}`)
                .then((response) => {
                    if (response.status === 200) {
                        this.setters.setSessionTasks(response.data);
                        resolve(response.data);
                    } else {
                        resolve(null);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    resolve(null);
                });
        });
    },

    getTaskByID(taskID) {
        return new Promise((resolve) => {
            controller
                .get(`/engine-tasks/all/${taskID}`)
                .then((response) => {
                    if (response.status === 200) {
                        this.setters.setSelectedTask(response.data);
                        resolve(response.data);
                    } else {
                        resolve(null);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    resolve(null);
                });
        });
    },


    getAnnotationLayers() {
        controller
            .get("/annotation-layers")
            .then((response) => {
                this.setters.setAnnotationLayers(response?.data);
            })
            .catch((err) => {
                console.log(err);
            });
    },

    getAnnotationLayersByCatid(catid) {
        controller
            .get(`/images/${catid}/annotation-layers`)
            .then((response) => {
                if (response.status === 200) {
                    this.setters.setAnnotationLayers(response.data);
                }
            })
            .catch((err) => {
                this.setters.setAnnotationLayers([]);
            });
    },

    getAnnotationLayerByID(id) {
        return new Promise((resolve) => {
            controller
                .get(`/annotation-layers/${id}`)
                .then((response) => {
                    if (response.status === 200) {
                        resolve(response.data);
                    } else {
                        resolve(null);
                    }
                })
                .catch((err) => {
                    resolve(null);
                });
        });
    },

    createAnnotationLayer(data) {
        return new Promise((resolve) => {
            controller
                .post("/annotation-layers", data)
                .then((response) => {
                    if (response.status === 201) {
                        this.setters.appendAnnotationLayer(response.data);
                        resolve(response.data);
                    } else {
                        resolve(null);
                    }
                })
                .catch((err) => {
                    resolve(null);
                });
        });
    },

    updateAnnotationLayer(data, annotationLayerID) {
        return new Promise((resolve) => {
            controller
                .put(`/annotation-layers/${annotationLayerID}`, data)
                .then((response) => {
                    if (response.status === 200) {
                        // update annotation layer in state?

                        resolve(true); // success = true
                    } else {
                        resolve(false); // success = false
                    }
                })
                .catch((err) => {
                    resolve(false); // success = false
                });
        });
    },

    deleteAnnotationLayer(annotationLayerID) {
        return new Promise((resolve) => {
            controller
                .delete(`/annotation-layers/${annotationLayerID}`)
                .then((response) => {
                    resolve(response.status === 204);
                })
                .catch((err) => resolve(false));
        });
    },

    createAnnotation(annotationLayerID, annotation) {
        controller
            .post(`/annotation-layers/${annotationLayerID}/annotations`,annotation)
            .then((response) => {
                console.log(response);
            })
            .catch((err) => {
                console.log(err);
            });
    },

    getAnnotations(annotationLayerID) {
        return new Promise((resolve) => {
            controller.get(`/annotation-layers/${annotationLayerID}/annotations`)
                .then((response) => {
                    console.log(response);
                    resolve(response.data);
                })
                .catch((err) => {
                    console.log(err);
                    resolve([]);
                });
        });
    },
};

/* 
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::::::::::::::::::::: GEOSERVER API ::::::::::::::::::::::::::::::::
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
*/

export const geoserverAPI = {
    getLayers() { },

    getLayerInfo(layer) {
        const headers = authorize(GEOSERVER_USERNAME, GEOSERVER_PASSWORD);
        const url = `${GEOSERVER_PROTOCOL}://${layer.ip}:${layer.port}/geoserver/rest/layers/${layer.layerLocation}.json`;

        return new Promise((resolve) => {
            fetch(url, { method: "GET", headers, redirect: "follow" })
                .then((response) => response.json())
                .then((data) => {
                    resolve(data);
                })
                .catch((err) => {
                    console.error(
                        "there was an error processing this request",
                        layer,
                        err
                    );
                    resolve(null);
                });
        });
    },

    getWorkspaceStyles(layer) {
        const headers = authorize(GEOSERVER_USERNAME, GEOSERVER_PASSWORD);
        const workspace = layer.layerLocation.split(":")[0];
        const url = `${GEOSERVER_PROTOCOL}://${layer.ip}:${layer.port}/geoserver/rest/workspaces/${workspace}/styles.json`;

        return new Promise((resolve) => {
            fetch(url, { headers })
                .then((response) => response.json())
                .then((data) => {
                    resolve(data.styles.style.map((s) => s.name));
                })
                .catch((err) => console.error(err));
        });
    },

    getStyleDocument(styleDef, layer) {
        const headers = authorize(GEOSERVER_USERNAME, GEOSERVER_PASSWORD);
        headers.append("Content-Type", "application/vnd.ogc.sld+xml");
        const [workspace, styleName] = styleDef.split(":");
        const url = `${GEOSERVER_PROTOCOL}://${layer.ip}:${layer.port}/geoserver/rest/workspaces/${workspace}/styles/${styleName}`;
        // TODO: For now, hardcoding style values will be best. later will work out this rest call to get this working so we can pull in style data from any source
        return new Promise((resolve) => {
            fetch(url, { headers })
                .then((response) => response.text())
                .then((data) => {
                    debugger;
                })
                .catch((err) => {
                    debugger;
                });
        });
    },

    getLayerCoverage(layer) {
        const headers = authorize(GEOSERVER_USERNAME, GEOSERVER_PASSWORD);
        const [workspace, layerName] = layer.layerLocation.split(":");
        const url = `${GEOSERVER_PROTOCOL}://${layer.ip}:${layer.port}/geoserver/rest/workspaces/${workspace}/coveragestores/${layerName}/coverages/${layerName}.json`;
        return new Promise((resolve) => {
            fetch(url, { headers })
                .then((response) => response.json())
                .then((data) => {
                    resolve(data);
                })
                .catch((err) => {
                    resolve(null);
                });
        });
    },

    isValidImageSrc(url) {
        return new Promise((resolve) => {
            fetch(url)
                .then((response) => response.text())
                .then((text) => {
                    try {
                        decodeURIComponent(escape(text)); // if this decoding works, then we received XML saying the image was invalid
                        resolve(false);
                    } catch (err) {
                        resolve(url);
                    }
                })
                .catch((err) => resolve(false));
        });
    },

    getImageThumbnail(layer, feature) {
        let url = `${GEOSERVER_PROTOCOL}://${layer.ip}:${layer.port}/geoserver/wms`;
        const params = {
            service: "WMS",
            version: "1.1.0",
            request: "GetMap",
            layers: `images:${feature.properties.catid}`,
            bbox: feature.properties.bbox,
            width: 512,
            height: 512,
        };

        url = formatQueryString(url, params);

        fetch(url).then((response) => console.log(response));
    },

    countFeatures(layer) {
        this.setters.setNumFeatures(null);

        const headers = authorize(GEOSERVER_USERNAME, GEOSERVER_PASSWORD);
        let url = `${GEOSERVER_PROTOCOL}://${layer.ip}:${layer.port}/geoserver/wfs`;

        const params = {
            service: "WFS",
            version: "1.1",
            request: "GetFeature",
            typeName: layer.layerLocation,
            resultType: "hits",
            outputFormat: "JSON",
        };

        if (!!layer.mapSettings?.CQL_FILTER)
            params.CQL_FILTER = layer.mapSettings.CQL_FILTER;

        fetch(formatQueryString(url, params), { headers })
            .then((response) => response.text())
            .then((data) => {
                try {
                    const count = parseInt(
                        data.match(/numberOfFeatures="\d+"/)[0].match(/\d+/)[0]
                    );
                    this.setters.setNumFeatures(count);
                } catch (err) {
                    this.setters.setNumFeatures(0);
                }
            })
            .catch((err) => {
                this.setters.setNumFeatures(0);
            });
    },

    getLayerAnalytics(layer) {
        if (this.state.selectedLayer?.id === layer.id) {
            this.setters.setSelectedLayerAnalytics(null);
        } else {
            return; // we don't need analytics for layers that are not the current selected layer
        }

        if (!layer.id || !layer.port ||  !layer.layerType.match(/(feature)|(data)/gi)) return;

        const headers = authorize(GEOSERVER_USERNAME, GEOSERVER_PASSWORD);
        let url = `${GEOSERVER_PROTOCOL}://${layer.ip}:${layer.port}/geoserver/ows`;
        const params = {
            service: "WFS",
            version: "1.0.0",
            request: "GetFeature",
            typeName: layer.layerLocation,
            outputFormat: "JSON",
        };

        if (!!layer.mapSettings?.CQL_FILTER)
            params.CQL_FILTER = layer.mapSettings.CQL_FILTER;

        fetch(formatQueryString(url, params), { headers })
            .then((response) => {
                return response.json();
            })
            .then(async (data) => {
                let features;
                if (data.features?.length > 1) {
                    // there is more than 1 feature returned
                    // sort the returned features and add a thumbnail url on each
                    let time1, time2;
                    features = data.features.sort((f1, f2) => {
                        f1.thumbnailURL = feature2ProcessedThumbnail(layer, f1);
                        f1.rawURL = feature2RawSrc(layer, f1);
                        f1.hasValidThumbnail = "unknown";
                        // f1.hasValidThumbnail = f1.hasValidThumbnail ?? await this.dataPrepAPI.isValidImageSrc(f1.thumbnailURL)
                        f2.thumbnailURL = feature2ProcessedThumbnail(layer, f2);
                        f2.rawURL = feature2RawSrc(layer, f2);
                        f2.hasValidThumbnail = "unknown";
                        // f2.hasValidThumbnail = f2.hasValidThumbnail ?? await this.dataPrepAPI.isValidImageSrc(f2.thumbnailURL)

                        time1 = DateTime.fromISO(f1.properties.timestamp);
                        time2 = DateTime.fromISO(f2.properties.timestamp);
                        return time1 < time2 ? -1 : 1;
                    });

                    // for (let feature of features) {
                    //     feature.hasValidThumbnail = await this.dataPrepAPI.isValidImageSrc(feature.thumbnailURL)
                    // }
                } else if (data.features?.length === 1) {
                    // there is only one feature returned
                    features = data.features;
                    features[0].thumbnailURL = feature2ProcessedThumbnail(
                        layer,
                        features[0]
                    );
                    features[0].rawURL = feature2RawSrc(layer, features[0]);
                    // features[0].hasValidThumbnail = "unknown"
                    features[0].hasValidThumbnail =
                        await this.methods.isValidImageSrc(
                            features[0].thumbnailURL
                        );
                } else {
                    // there are not features returned
                    features = [];
                }
                this.setters.setSelectedLayerAnalytics(features);
            })
            .catch((err) => {
                this.setters.setSelectedLayerAnalytics(null);
                console.log("There was an error processing this request", err);
            });
    },

    getDepthJson({ catID, runID, typeName, returnLink = false }) {
        const params = {
            service: "WFS",
            version: "2.0.0",
            request: "GetFeature",
            typeName: typeName ?? "casi:detection_results_z",
            outputFormat: "application/json",
            viewParams: `image_catid:${catID};run_id:${runID}`,
        };

        if (returnLink) {
            return controller.getUri({
                method: "get",
                url: GEOSERVER_BASE + '/casi/ows',
                params
            })
        }

        return new Promise((resolve) => {
            controller
                .get(GEOSERVER_BASE + `/casi/ows`, { params })
                .then((response) => {
                    resolve(response.status === 200 ? response.data : null);
                })
                .catch((err) => {
                    console.log(err);
                    resolve(null);
                });
        });
    },

    getShapefile({ catID, runID, typeName, returnLink = false }) {
        const params = {
            service: "WFS",
            version: "2.0.0",
            request: "GetFeature",
            typeName: typeName ?? "casi:detection_results_z",
            srsName: "EPSG:4326",
            outputFormat: "shape-zip",
            viewParams: `image_catid:${catID};run_id:${runID}`,
        }
        if (returnLink) {
            return controller.getUri({
                method: "get",
                url: GEOSERVER_BASE + '/casi/ows',
                params
            })
        }
    },
};


/* 
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::::::::::::::::::::: LINK SHORTENER API ::::::::::::::::::::::::::::::::
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
*/

export const linkShortenerAPI = {
    createShortLink(longUrl, customSlug) {
        const request = {
            url: `http://${LINK_SHORTENER_HOST}:${LINK_SHORTENER_PORT}/rest/v2/short-urls`,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "x-api-key": LINK_SHORTENER_API_KEY,
            },
            method: "post",
            data: { longUrl, customSlug },
        };

        return new Promise((resolve) => {
            controller(request)
                .then((response) => resolve(response.data))
                .catch((err) => {
                    console.log({ err });
                    resolve({ error: "Could not create short URL" });
                });
        });
    },

    updateShortLink(longUrl, customSlug) {
        const request = {
            url: `http://${LINK_SHORTENER_HOST}:${LINK_SHORTENER_PORT}/rest/v2/short-urls/${customSlug}`,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "x-api-key": LINK_SHORTENER_API_KEY,
            },
            method: "patch",
            data: { longUrl },
        };

        return new Promise((resolve) => {
            controller(request)
                .then((response) => {
                    if (response.status === 204) {
                        resolve({
                            shortUrl: `http://${LINK_SHORTENER_HOST}:${LINK_SHORTENER_PORT}/${customSlug}`,
                        });
                    } else {
                        resolve({ error: "Could not update short URL" });
                    }
                    resolve(response.data);
                })
                .catch((err) => {
                    resolve({ error: "Could not update short URL" });
                });
        });
    },
};

export default methods;
