import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
// =============================== STATE ===============================
import { DataPrepContext } from "../../../state/explorer/explorerProvider";

// =============================== DZYNE COMPONENTS ===============================
import SelectionTable from "../../dzyne_components/ui/tables/SelectionTable/SelectionTable";
import InlineButton from "../../dzyne_components/ui/buttons/InlineButton/InlineButton";

// =============================== ICONS ===============================
import Export from "../../../assets/icons/export.svg";

// =============================== CONFIG ===============================
import config from "../../../config.json";
import ResultsTimeline from "./ResultsTimeline";
import { downloadJSON } from "../../../js/generalHelpers";

const GEOSERVER_PROTOCOL = process.env.REACT_APP_GEOSERVER_PROTOCOL || window._env_?.geoserverProtocol || config.geoserverProtocol || "http";
const GEOSERVER_HOST = process.env.REACT_APP_GEOSERVER_HOST || window._env_?.geoserverHost || config.geoserverHost || window.location.hostname;
const GEOSERVER_PORT = process.env.REACT_APP_GEOSERVER_PORT || window._env_?.geoserverPort || config.geoserverPort || 4013;

const CONTROLLER_PROTOCOL =
    process.env?.REACT_APP_CONTROLLER_PROTOCOL ||
    window._env_?.controllerProtocol ||
    config.controllerProtocol ||
    "http";
const CONTROLLER_HOST =
    process.env?.REACT_APP_CONTROLLER_HOST ||
    window._env_?.controllerHost ||
    config.controllerHost ||
    window.location.hostname;
const CONTROLLER_PORT =
    process.env?.REACT_APP_CONTROLLER_PORT ||
    window._env_?.controllerPort ||
    config.controllerPort ||
    4011;
const CONTROLLER_BASE = `${CONTROLLER_PROTOCOL}://${CONTROLLER_HOST}:${CONTROLLER_PORT}/api/v4/`;

const DOWNLOAD_PROXY_PROTOCOL = process.env?.REACT_APP_DOWNLOAD_PROXY_PROTOCOL || window._env_?.downloadProxyProtocol || config.downloadProxyProtocol || "http";
const DOWNLOAD_PROXY_HOST = process.env?.REACT_APP_DOWNLOAD_PROXY_HOST || window._env_?.downloadProxyHost || config.downloadProxyHost || window.location.hostname;
const DOWNLOAD_PROXY_PORT = process.env?.REACT_APP_DOWNLOAD_PROXY_PORT || window._env_?.downloadProxyPort || config.downloadProxyPort || 8080; 
const DOWNLOAD_PROXY_ROUTE = process.env?.REACT_APP_DOWNLOAD_PROXY_ROUTE || window._env_?.downloadProxyROUTE || config.downloadProxyRoute || "/"; 
const DOWNLOAD_PROXY_BASE = `${DOWNLOAD_PROXY_PROTOCOL}://${DOWNLOAD_PROXY_HOST}:${DOWNLOAD_PROXY_PORT}${DOWNLOAD_PROXY_ROUTE}`

export default function SelectedTaskDisplay({ task, detectionResults, setDetectionResults, detectorType }) {
    // STATE
    const { state, setters, geoserverAPI, dataPrepAPI, methods } = useContext(DataPrepContext);
    const [tableHeight, setTableHeight] = useState(1e10);
    const [initTimeline, setInitTimeline] = useState(false);
    const [renderedImageBase, setRenderedImageBase] = useState(null);
    const [buildingOpacity, setBuildingOpacity] = useState(1);
    const [loadingResults, setLoadingResults] = useState(false);
    const [selectedDetection, setSelectedDetection] = useState(null);

    // EVENTS

    const handleTimelineInitClick = () => {
        setInitTimeline(true);
    };

    const handleAddAll = async () => {
        const resultTrs = [...document.querySelectorAll(".results-row")];
        for (let res of resultTrs) {
            res.click();
            await new Promise(r => setTimeout(r, 500))
        }
    };

    const handleClearBuildingsClick = () => {
        methods.clearDepthResults();
        renderedImageBase && setters.deleteLayer(renderedImageBase?.layer?.id);
    };

    const handleZoomToBuildingsClick = () => {
        methods.zoomToDepthDetection();
    };

    const handleDownloadGeoJSON = async () => {
        const data = await geoserverAPI.getDepthJson({ catID: selectedDetection.catID, runID: selectedDetection.runID });
        downloadJSON(data, selectedDetection.runID + ".geojson")
    };

    const handleDepthOpacityChange = (e) => {
        setBuildingOpacity(parseFloat(e.target.value));
    };

    const handleDepthOpacityChangeCommit = () => {
        methods.changeDepthEntityOpacity(buildingOpacity);
    };

    const handleResultsClick = (data, e) => {
        switch (data.status) {
            case "SUCCESS":
                const runID = data.run_id;
                const coverage = data.metadata?.coverage;
                const catIDs = !!coverage ? Object.keys(coverage) : null;
                setDetectionResults(catIDs.map((cid) => ({ runID, catID: cid })));
                break;
            case "FAILED":
                console.log("FAILED", data);
                break;
            default:
                break;
        }
    };

    const handleDetectionClick = async (data, e) => {
        setLoadingResults(true);
        switch (true) {
            case detectorType.match(/depth/gi):
                setSelectedDetection(data);
                const depthJSON = await geoserverAPI.getDepthJson({
                    catID: data.catID,
                    runID: data.runID,
                });
                depthJSON && methods.renderDepthResults(depthJSON);
                const baseImageLayer = {
                    layerType: "feature",
                    type: "dzyne_server",
                    name: `image-${data.catID}`,
                    layerLocation: `images:${data.catID}`,
                    ip: GEOSERVER_HOST,
                    port: GEOSERVER_PORT,
                    aoi: { type: "none" },
                    isActive: true,
                };
                const imageBase = await setters.appendLayer(baseImageLayer);
                setRenderedImageBase(imageBase);
                break;
            case detectorType === "vlcd_land_cover" || !!detectorType?.match(/^landform/):
                const resultImageData = await dataPrepAPI.getImageResult(data.catID, data.runID);
                if(!!resultImageData) {
                    const vlcdLayer = {
                        layerType: "feature",
                        type: "dzyne_server",
                        name: `detection_results_${resultImageData.catid}`,
                        layerLocation: `images:${resultImageData.catid}`,
                        ip: GEOSERVER_HOST,
                        port: GEOSERVER_PORT,
                        aoi: {type: "none"},
                        isActive: true
                    }
                    await setters.appendLayer(vlcdLayer);
                }
                break;
            default:
                const resultLayer = {
                    layerType: "feature",
                    type: "dzyne_server",
                    name: `detection_results_${data.catID}`,
                    layerLocation: "casi:detection_results",
                    ip: GEOSERVER_HOST,
                    port: GEOSERVER_PORT,
                    mapSettings: {
                        viewparams: `image_catid:${data.catID};run_id:${data.runID}`,
                    },
                    aoi: { type: "none" },
                    isActive: true,
                };
                await setters.appendLayer(resultLayer);
        }
        setLoadingResults(false);
    };

    // EFFECTS
    useLayoutEffect(() => {
        const table = document.querySelector("#task-selection-table");
        if (table) {
            const rect = table.getBoundingClientRect();
            setTableHeight(window.innerHeight - rect.top - 8);
        }
    }, [detectionResults]);

    // opacity change
    useEffect(() => {
        setBuildingOpacity(1);
    }, [state.depthDataSource]);

    useEffect(() => {
        return () => {
            methods.clearDepthResults();
            setters.setSelectedTask(null);
            renderedImageBase && setters.deleteLayer(renderedImageBase?.layer?.id);
        };
    }, []);

    console.log({ detectionResults });

    return (
        <>
            {loadingResults && <div className="font-bold">LOADING RESULTS...</div>}
            {!!detectionResults && !initTimeline && !detectorType.match(/depth/gi) && (
                <InlineButton onClick={handleTimelineInitClick} style={{ transform: "scale(0.75)" }}>
                    Init Timeline
                </InlineButton>
            )}

            {!!detectionResults &&  !detectorType.match(/depth/gi) && <>
                <InlineButton onClick={handleAddAll}>Add All</InlineButton>
                {
                    !!detectionResults?.[0]?.runID
                    &&
                    <InlineButton >
                        {/* <a href={CONTROLLER_BASE + `engine-tasks/download/${detectionResults?.[0]?.runID}`} target="_blank">
                            Download
                            <img src={Export} alt="download" className="inline-block relative bottom-1" />
                        </a> */}
                        <a href={DOWNLOAD_PROXY_BASE + `/${detectionResults?.[0]?.runID?.split?.("-")?.[0]}`} target="_blank">
                            Download
                            <img src={Export} alt="download" className="inline-block relative bottom-1" />
                        </a>
                    </InlineButton>
                }
            </>}

            {!!detectionResults && detectorType.match(/depth/gi) && !!state.depthDataSource && (
                <>
                    {state.privilege !== "demo" && (
                        <InlineButton onClick={handleClearBuildingsClick} style={{ transform: "scale(0.75)" }}>
                            Clear Buildings
                        </InlineButton>
                    )}
                    {detectorType.match(/depth/gi) && (
                        <>
                            <InlineButton onClick={handleZoomToBuildingsClick} style={{ transform: "scale(0.75)" }}>
                                Zoom To
                            </InlineButton>
                            {selectedDetection && (
                                <>
                                    <InlineButton style={{ transform: "scale(0.75)" }} onClick={handleDownloadGeoJSON}>
                                        GeoJSON
                                        <img src={Export} alt="download" className="inline-block relative bottom-1" />
                                    </InlineButton>
                                    <InlineButton style={{ transform: "scale(0.75)" }}>
                                        <a href={geoserverAPI.getShapefile({ catID: selectedDetection.catID, runID: selectedDetection.runID, returnLink: true })} target="_blank">
                                            Shapefile
                                            <img src={Export} alt="download" className="inline-block relative bottom-1" />
                                        </a>
                                    </InlineButton>
                                </>
                            )}
                        </>
                    )}
                    <br />
                    <input type="range" min="0" max="1" step="0.01" onChange={handleDepthOpacityChange} onMouseUp={handleDepthOpacityChangeCommit} />
                </>
            )}

            {!!detectionResults && initTimeline && <ResultsTimeline detectionResults={detectionResults} />}
            {/* <InlineButton onClick={handleBackClick} className="text-left">View All Tasks</InlineButton> */}
            {!detectionResults ? (
                <SelectionTable
                    id="task-selection-table"
                    containerClassName="h-3/4"
                    containerStyle={{ height: tableHeight }}
                    tdStyle={{ color: "var(--neutral0)" }}
                    data={task.runs}
                    columns={[
                        { key: "run_id", name: "ID" },
                        { key: "status", name: "Status" },
                    ]}
                    onRowClick={handleResultsClick}
                />
            ) : (
                <SelectionTable id="task-selection-table" data={detectionResults} columns={[{ key: "catID", name: "Image ID" }]} containerStyle={{ height: tableHeight }} tdStyle={{ color: "var(--neutral0)" }} onRowClick={handleDetectionClick} trClassName="results-row" />
            )}
        </>
    );
}
